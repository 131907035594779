figure img, .button, .testimonial-block, input, .button1, textarea, iframe {
	position: relative;
	behavior:url(../js/PIE.htc);
}

.cameraSlide {
	bottom: 0;
	left: 400px;
	position: absolute;
	right: 0;
	top: 0px;
	width: 100%;
}