abbr,
acronym,
address,
applet,
article,
aside,
audio,
blockquote,
big,
body,
center,
canvas,
caption,
cite,
code,
command,
datalist,
dd,
del,
details,
dfn,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
header,
hgroup,
html,
i,
iframe,
ins,
kbd,
keygen,
legend,
meter,
object,
ol,
output,
pre,
progress,
q,
s,
samp,
section,
small,
source,
strike,
strong,
sub,
sup,
table,
tbody,
tfoot,
th,
tdvideo,
tt,
u,
var {
  background-color: #dddddf;
  border: 0 none;
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
}
p {
  border: 0 none;
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
}
form {
  /* background-color: #dddddf; */
  border: 0 none;
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
}

ol,
ul {
  list-style: none;
  /* background-color: #dddddf; */
  border: 0 none;
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
}

blockquote,
q {
  quotes: none;
}

table,
table td {
  padding: 0;
  border: none;
  border-collapse: collapse;
}

img {
  vertical-align: top;
}

embed {
  vertical-align: top;
}
