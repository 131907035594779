/* General styles for the navigation links */
.nav a {
  float: left;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  padding: 2px 12px;
  display: block;
  font-family: "Roboto", sans-serif;
  color: #333;
}

/* Styles for the subnav containers */
.subnav {
  float: left;
  overflow: hidden;
  display: block;
}

/* Styles for the subnav buttons */
.subnav .subnavbtn {
  font-size: 18px;
  border: none;
  outline: none;
  color: white;
  padding: 5px 14px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  line-height: 1.4em;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

/* Hover and Active styles for the main menu buttons */
.nav .subnavbtn:hover,
.nav .subnavbtn.active {
  color: #333;
  background-color: #eee;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* Dropdown content container */
.subnav-content {
  display: none;
  position: absolute;
  left: 32%;
  background-color: #fff;
  width: 73%;
  z-index: 1;
  color: #333;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Hover and Active styles for the dropdown links */
.subnav-content a {
  float: left;
  color: #333;
  text-decoration: none;
  /* padding: 10px 14px; */
  display: block;
  font-family: "Roboto", sans-serif;
}

.subnav-content a:hover,
.subnav-content a.active {
  background-color: #eee;
  color: black;
  text-decoration: none;
  padding: 2px 8px;
  border-left: 1px solid #4a6d8c;
  border-right: 1px solid #4a6d8c;
}

/* Display dropdown content on hover */
.subnav:hover .subnav-content {
  display: block;
}

/* Active state for main menu items */
.nav .active a,
.nav a.active {
  background-color: #fff;
  color: #333;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.abc {
  border-bottom: none !important;
}
/* Add styles for the active tab */
.nav .active .subnavbtn {
  color: #333;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
