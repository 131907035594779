.wrapper_form {
  width: 98%;
  background: #fff;
  border-radius: 15px;
  box-shadow: -3px 10px 17px rgba(0, 0, 0, 0.32);
  margin: 2% auto !important;
  overflow: hidden;
  border-top: 1px solid #80808070;
}

.wd_log {
  max-width: 350px !important;
  margin: auto;
}
/* CSS */
.centered-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  margin: 1%;
}

.wrapper_form .title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  color: #fff;
  user-select: none;
  border-radius: 12px 12px 1px 1px;
  background-color: #4a6d8c;
}
.wrapper_form .titles {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 25px;
  color: #fff;
  user-select: none;
  border-radius: 15px 15px 0 0;
  background-color: #4a6d8c;
}

.wrapper_form form {
  padding: 10px 30px 50px 30px;
  /* background-color: #fff !important; */
}

.wrapper_form form .row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0%;
}

.wrapper_form form .column {
  flex: 1;
  padding: 0 10px;
}

.wrapper_form form .column2 {
  flex: auto;
  padding: 0 10px;
}

.ys {
  width: 89% !important;
  margin-left: 11% !important;
}

.wrapper_form form .column1 {
  flex: -moz-available;
  padding: 0 10px;
}

.wrapper_form form .field {
  height: 40px;
  width: 100%;
  position: relative;
  /* margin: 10px 0px; */
}
.mgtop {
  margin-top: -1%;
}
.mgtop1 {
  margin: 15px 0px;
}
.wrapper_form form .field input {
  height: 68%;
  width: 100%;
  outline: none;
  font-size: 15px;
  padding-left: 20px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-weight: 500;
}

.wrapper_form form .field input:focus,
form .field input:valid {
  border-color: #4a6d8c;
}

.wrapper_form form .field label {
  position: absolute;
  top: 50%;
  left: 20px;
  color: #999999;
  font-weight: 400;
  font-size: 17px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

form .field input:focus ~ label,
form .field input:valid ~ label {
  top: 5%;
  font-size: 16px;
  color: #333;
  /* background: #fff; */
  /* margin-bottom: 15px;
  margin-top: 15px; */
  transform: translateY(-70%);
}

form .field input[type="submit"] {
  color: #fff;
  border: none;
  padding-left: 0;
  margin-top: -10px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  background: #4a6d8c;
  transition: all 0.3s ease;
}

form .field input[type="submit"]:active {
  transform: scale(0.95);
}

form .signup-link {
  color: #262626;
  margin-top: 20px;
  text-align: center;
}

form .pass-link a,
form .signup-link a {
  color: #4158d0;
  text-decoration: none;
}

form .pass-link a:hover,
form .signup-link a:hover {
  text-decoration: underline;
}

.mg_l {
  margin-left: 1%;
}

.mg_2 {
  margin-left: 5%;
}

.mg {
  background-color: #262626;
}

.tabs {
  display: flex;
  margin: 1% 2% 3% 1%;
}

.tabs button {
  align-items: center;
  background-color: #4a6d8c;
  border: 2px solid #4a6d8c;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 600;
  /* height: 48px; */
  justify-content: center;
  letter-spacing: -0.8px;
  line-height: 11px;
  min-width: 140px;
  outline: 0;
  padding: 10px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-right: 3px;
  border-radius: 5px;
}

.tabs button:focus {
  color: #171e29;
}

.tabs button:hover {
  background-color: #6c90b0;
  border-color: #6c90b0;
  /* fill: #4f6f8b; */
}

.tabs button.active {
  background-color: white;
  border-color: #6c90b0;
  color: #4a6d8c;
}

/* Styling for the button */
.action-button {
  padding: 8px 15px;
  /* Add padding around the button text */
  background-color: #4a6d8c !important;
  /* Green background color */
  color: #fff !important;
  /* White text color */
  border: none;
  /* Remove default border */
  border-radius: 8px;
  /* Rounded corners */
  font-size: 16px;
  /* Larger font size for better readability */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  width: 66%;
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Smooth transitions */
  margin: 8% 0% 8% 0%;
}

/* Hover effect for the button */
.action-button:hover {
  color: #333 !important;
  background-color: #fff !important;
  /* Darker green for hover effect */
  transform: scale(1.05);
  border: 1px solid #4a6d8c !important;
  border-radius: 8px;
  /* Slightly scale up the button on hover */
}

.radio_align {
  display: flex;
  align-items: flex-start;
}

.mgl_1 {
  margin-left: 5px !important;
}

.input_wd {
  width: 50% !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  /* Allow items to wrap to the next line */
  margin-bottom: 0.5rem;
}

.column {
  flex: 1;
  /* Allow column to grow and fill available space */
}

.group-container {
  padding: 0.5rem;
  /* Space around the entire group */
  border: 1px solid #ccc;
  /* Optional border for better visual separation */
  border-radius: 5px;
  /* Optional rounded corners */
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.1rem;
  /* Space between checkboxes */
}

.checkbox-item input {
  margin-right: 0.5rem;
  /* Space between checkbox and label text */
}

.label-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* Space between label and its checkboxes */
}

/* RegisterForm css for tab 3  */
.register-form-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1px 5px 1px 15px;
  margin-bottom: 15px;
  max-width: 100%;
  margin: 0 10% 1% 2%;
  /* background-color: #4a6d8c;
  color: #fff; */
  background-color: #fff;
  color: #333;
}

.register-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.register-form-header h2 {
  margin: 0;
  font-size: 18px;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  /* color: #fff; */
}

.register-form-content {
  padding: 5px 5px 5px 15px;
  border: 1px solid #cebfbf;
  border-radius: 4px;
  margin-bottom: 1%;
}

.redio_btn_mrg {
  margin-right: 30% !important;
}

.btn_sub_que {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 5%; */
  /* padding: 8px 15px; */
  /* background-color: #4A6D8C !important; */
  /* color: #fff; */
  /* border: none; */
  /* border-radius: 8px; */
  /* font-size: 16px; */
  /* cursor: pointer; */
  /* width: 60%; */
  /* transition: background-color 0.3s ease, transform 0.2s ease; */
  /* margin: 8% 0% 8% 0%; */
}

.btn_sub {
  background-color: #4a6d8c !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.checkbox-group {
  display: inline-table !important;
  /* Use flexbox to arrange children in a row */
  flex-wrap: wrap !important;
}

.checkbox-group .MuiFormControlLabel-root {
  margin: 0;
  /* Remove default margin */
}

.error-message {
  color: red;
  font-size: 0.875em;
  /* margin-top: 5px; */
  line-height: 1.4em;
}

.pd1 {
  padding: 2px 0 0 0 !important;
}

.date-display {
  font-size: 16px;
  /* Adjust as needed */
  font-weight: bold;
  /* Make it stand out */
  margin-bottom: 8px;
  /* Space between date and label */
}

.emp_add_text {
  width: 100% !important;
  /* padding: 8px !important; */
  /* height: auto !important; */
  color: black !important;
}

.radio-row {
  display: flex;
  flex-direction: column;
  /* Stack radio buttons vertically */
  /* margin-bottom: 10px; */
}

/* Radio Button Label */
.radio-label {
  margin-bottom: 10px;
  /* Space below each radio button label */
}

/* Content Displayed Below Each Radio Button */
.radio-content {
  /* margin-top: 10px; Space between the radio button and its content */
  border: 1px solid #ddd;
  /* padding: 20px; */
  border-radius: 4px;
  background-color: #f9f9f9;
  width: 100%;
  box-sizing: border-box;
}

/* CSS File Upload Section */
.css-file-content {
  /* Additional styling specific to the CSS file upload section */
}

/* Manual User Addition Section */
.manual-user-content {
  /* Additional styling specific to the manual user addition section */
}

/* Form Inputs */
.form-input {
  margin-bottom: 10px;
}

.form-input label {
  display: block;
  margin-bottom: 5px;
}

.form-input input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

/* Buttons */
.pd2 {
  padding: 4px 5px !important;
  margin: 0.5% 0 0 0.2% !important;
  width: 13% !important;
  height: 35px;
}

.pdm {
  padding: 1px !important;
  margin-left: 2% !important;
}

.frm_wd {
  width: 91% !important;
  margin: 2% auto;
  border: 1px solid #80808040;
  border-radius: 13px;
  margin-right: auto;
  margin-bottom: 2%;
}
.frm_wd1 {
  width: 75% !important;
  margin: 2% auto;
  border: 1px solid #80808040;
  border-radius: 13px;
  margin-right: auto;
  margin-bottom: 2%;
}
.dropdown_p1 {
  width: 100%;
  border-radius: 8px;
  height: 68%;
  /* border-bottom: 2px solid gray; */
  /* border-right: 2px solid gray; */
}
.hr1 {
  color: #519ada91;
  margin: 0;
}

.hr_ttl {
  /* color: #519ada91 ; */
  border: 5px solid #407cb1e5;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 3px;
}
.hr_dt {
  border: 1.5px dotted #1684e4;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 3px;
}
.drp_ln_hg {
  line-height: 25px !important;
}
.drp_mg {
  margin: 1% 1% 1% 3% !important ;
}
.drp1 {
  width: 100%;
  border-radius: 8px;
  height: 70%;
  padding-left: 2%;
}
.txt_ttl {
  font-weight: bold !important;
  font-size: large !important;
}
.txt_ttl1 {
  font-weight: bold !important;
  font-size: medium !important;
}
.new_app_btn {
  margin: ".4%" !important;
  padding: 8px 15px;
  /* Add padding around the button text */
  background-color: #4a6d8c !important;
  /* Green background color */
  color: #fff !important;
  /* White text color */
  border: none;
  /* Remove default border */
  border-radius: 8px;
  /* Rounded corners */
  font-size: 16px;
  /* Larger font size for better readability */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  width: auto;
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Smooth transitions */
  /* margin: 8% 0% 8% 0%; */
  float: right;
}
.new_app_btn:hover {
  color: #333 !important;
  background-color: #fff !important;
  /* Darker green for hover effect */
  transform: scale(1.05);
  border: 1px solid #4a6d8c !important;
  border-radius: 8px;
  /* Slightly scale up the button on hover */
}
/* Custom scrollbar styles for WebKit browsers */
::-webkit-scrollbar {
  width: 2px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #4a6d8c; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}
/* Custom scrollbar styles for Firefox */
* {
  scrollbar-width: thin; /* Set the scrollbar width */
  scrollbar-color: #4a6d8c transparent; /* Thumb color and track color */
}
/* for Scrolling Annocements */
.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  color: #4a6d8c; /* Text color */
  background-color: white; /* Background color */
  padding: 0; /* Padding */
  position: relative;
  margin-top: 1%;
  height: 21px;
  line-height: 21px;
}
.pipe {
  margin-left: 20px; /* Space before the pipe */
}
.marquee-content {
  display: inline-block;
  animation: marquee 15s linear infinite; /* Adjust duration for speed */
  font-weight: 400;
}
.marquee.paused .marquee-content {
  animation-play-state: paused; /* Pause the animation */
}
@keyframes marquee {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  100% {
    transform: translateX(-100%); /* Move to the left */
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is on top */
}

.modal {
  background: white;
  width: 500px; /* Set width as needed */
  padding: 17px;
  border-radius: 5px;
  position: relative; /* Position relative for better control */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* For absolute positioning of the close button */
}
.modal-title {
  flex-grow: 1; /* Allow the title to take up available space */
  text-align: center; /* Center the title text */
  margin: 0; /* Remove default margin */
}
.close-button {
  background: none;
  border: none;
  font-size: 17px; /* Adjust size as needed */
  cursor: pointer;
  color: #333; /* Change color as needed */
  position: absolute; /* Position it absolutely */
  right: -10px; /* Adjust as needed */
  top: -13px; /* Adjust as needed */
}

.close-button:hover {
  color: red; /* Change color on hover for better UX */
}
.heading_tablecell {
  font-size: 0.8rem;
  padding: 1px;
  background-color: #4a6d8c;
  border: 1px solid gray;
  color: white;
  text-align: center;
}

.abc {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68vh;
}
.cursr_view {
  cursor: default;
}
